import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getUser as getUserApi,
  getAllUser as getAllUserApi,
  createUser as createUserApi,
  //updateUser as updateUserApi,
  deleteUser as deleteUserApi,
} from "helpers";

import { userApiResponseSuccess, userApiResponseError } from "./actions";
import { UserActionTypes } from "./constants";

type UserData = {
  payload: {
    _id?: string;
    username?: string;
    adresse: string;
    email: string;
    password: string;
    fullName?: string;
    phone: number;
    role?: string;
    isActive?: boolean;
    token?: string;
    compte?: string[];
  };
  type: string;
};

function* getAllUser(): SagaIterator {
  try {
    const response = yield call(getAllUserApi);
    if (response) {
      yield put(
        userApiResponseSuccess(UserActionTypes.ALL_USER, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du deal.
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.ALL_USER, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createUser({
  payload: { adresse, email, password, fullName, phone, role, isActive },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(createUserApi, {
      adresse,
      email,
      password,
      fullName,
      phone,
      role,
      isActive,
    });
    if (response) {
      yield put(
        userApiResponseSuccess(UserActionTypes.CREATE_USER, response.data)
      );
    }
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.CREATE_USER, error));
  }
}

function* deleteUser({ payload: { _id }, type }: UserData): SagaIterator {
  try {
    const response = yield call(deleteUserApi, _id);

    if (response) {
      yield put(
        userApiResponseSuccess(UserActionTypes.DELETE_USER, response.data)
      );
    }
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.DELETE_USER, error));
  }
}

function* getUser({ payload: { _id }, type }: UserData): SagaIterator {
  try {
    const response = yield call(getUserApi, _id);

    if (response) {
      yield put(
        userApiResponseSuccess(UserActionTypes.GET_USER, response.data)
      );
    }
  } catch (error: any) {
    yield put(userApiResponseError(UserActionTypes.GET_USER, error));
  }
}

export function* watchCreateUser() {
  yield takeEvery(UserActionTypes.CREATE_USER, createUser);
}
export function* watchGetUsers() {
  yield takeEvery(UserActionTypes.ALL_USER, getAllUser);
}
export function* watchGetUser() {
  yield takeEvery(UserActionTypes.GET_USER, getUser);
}

export function* watchDeleteUser() {
  yield takeEvery(UserActionTypes.DELETE_USER, deleteUser);
}

function* userSaga() {
  yield all([fork(watchCreateUser)]);
  yield all([fork(watchGetUser)]);
  yield all([fork(watchGetUsers)]);
  yield all([fork(watchDeleteUser)]);
}

export default userSaga;
