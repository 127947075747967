import { APICore } from "./apiCore"; // Assurez-vous d'importer la classe APICore appropriée.

const api = new APICore(); // Initialisez l'instance APICore appropriée.

function getTicket(ticketId: any) {
  const baseUrl = `/ticket/` + ticketId; // Assurez-vous d'ajuster l'URL de base pour la création de tickets en fonction de votre API.
  return api.get(`${baseUrl}`, ""); // Assurez-vous d'appeler la fonction appropriée pour la création d'un ticket.
}

function getTickets() {
  const baseUrl = "/ticket"; // Assurez-vous d'ajuster l'URL de base pour la récupération de la liste des tickets en fonction de votre API.
  return api.get(`${baseUrl}`, ""); // Assurez-vous d'appeler la fonction appropriée pour la récupération de la liste des tickets.
}

function createTicket(params: {
  objet: string;
  description: string;
  departement: string;
  priorite: boolean;
  fichiers?: string[];
  createdBy: string;
}) {
  const baseUrl = "/ticket"; // Assurez-vous d'ajuster l'URL de base pour la création de tickets en fonction de votre API.
  return api.createWithFile(`${baseUrl}`, params); // Assurez-vous d'appeler la fonction appropriée pour la création d'un ticket.
}

function createComment(params: {
  description: string;
  createdBy: string;
  isTech: boolean;
  ticketId: string;
  fichiers?: string[];
  statut: string;
}) {
  const baseUrl = "/ticket/comment";
  return api.createWithFile(`${baseUrl}`, params);
}

function updateTicket(ticketId: string, ticketData: any) {
  const baseUrl = `/ticket/${ticketId}`;
  return api.update(`${baseUrl}`, ticketData);
}

function deleteTicket(_id: any) {
  const baseUrl = `/ticket/${_id}`;
  return api.delete(`${baseUrl}`);
}

export { createTicket, updateTicket, deleteTicket, getTickets, getTicket, createComment };
