import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getTicket as getTicketApi,
  getTickets as getTicketsApi,
  createTicket as createTicketApi,
  createComment as createCommentApi,
  updateTicket as updateTicketApi,
  deleteTicket as deleteTicketApi,
} from "helpers";

import { ticketApiResponseSuccess, ticketApiResponseError } from "./actions"; // Assurez-vous d'importer les actions appropriées depuis votre fichier d'actions.
import { TicketActionTypes } from "./constants"; // Assurez-vous d'importer les constantes appropriées depuis votre fichier de constantes.

type TicketData = {
  payload: {
    _id?: string;
    departement: string;
    description: string;
    objet: string;
    priorite: boolean;
    fichiers?: string[];
    createdBy: string;
    statut?: string;
  };
  type: string;
};
type CommentData = {
  payload: {
    _id?: string;
    description: string;
    createdBy: string;
    isTech: boolean;
    ticketId: string;
    fichiers?: string[];
    statut: string;
  };
  type: string;
};

function* getTickets(): SagaIterator {
  try {
    const response = yield call(getTicketsApi);
    if (response) {
      yield put(
        ticketApiResponseSuccess(TicketActionTypes.ALL_TICKET, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du ticket.
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.ALL_TICKET, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du ticket.
  }
}

function* createTicket({
  payload: {
    objet,
    description,
    departement,
    priorite,
    fichiers,
    createdBy
  },
  type,
}: TicketData): SagaIterator {
  try {
    const response = yield call(createTicketApi, {
      objet,
      description,
      departement,
      priorite,
      fichiers,
      createdBy
    });
    if (response) {
      yield put(
        ticketApiResponseSuccess(TicketActionTypes.CREATE_TICKET, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du ticket.
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.CREATE_TICKET, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du ticket.
  }
}

function* createComment({
  payload: {
    description,
    createdBy,
    ticketId,
    fichiers,
    isTech,
    statut
  },
  type,
}: CommentData): SagaIterator {
  try {
    const response = yield call(createCommentApi, {
      description,
      createdBy,
      ticketId,
      fichiers,
      isTech,
      statut
    });
    if (response) {
      yield put(
        ticketApiResponseSuccess(TicketActionTypes.CREATE_COMMENT, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du ticket.
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.CREATE_COMMENT, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du ticket.
  }
}

function* deleteTicket({ payload: { _id }, type }: TicketData): SagaIterator {
  try {
    const response = yield call(deleteTicketApi, _id);

    if (response) {
      yield put(
        ticketApiResponseSuccess(TicketActionTypes.DELETE_TICKET, response.data)
      );
    }
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.DELETE_TICKET, error));
  }
}

function* getTicket({ payload: { _id }, type }: TicketData): SagaIterator {
  try {
    const response = yield call(getTicketApi, _id);

    if (response) {
      yield put(
        ticketApiResponseSuccess(TicketActionTypes.GET_TICKET, response.data)
      );
    }
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.GET_TICKET, error));
  }
}

function* updateTicket({ payload: { ticketId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateTicketApi, ticketId, updatedData);
    yield put(
      ticketApiResponseSuccess(TicketActionTypes.UPDATE_TICKET, response.data)
    );
  } catch (error: any) {
    yield put(ticketApiResponseError(TicketActionTypes.UPDATE_TICKET, error));
  }
}

export function* watchCreateTicket() {
  yield takeEvery(TicketActionTypes.CREATE_TICKET, createTicket);
}
export function* watchCreateComment() {
  yield takeEvery(TicketActionTypes.CREATE_COMMENT, createComment);
}
export function* watchGetTickets() {
  yield takeEvery(TicketActionTypes.ALL_TICKET, getTickets);
}
export function* watchGetTicket() {
  yield takeEvery(TicketActionTypes.GET_TICKET, getTicket);
}
export function* watchUpdateTicket() {
  yield takeEvery(TicketActionTypes.UPDATE_TICKET, updateTicket);
}

export function* watchDeleteTicket() {
  yield takeEvery(TicketActionTypes.DELETE_TICKET, deleteTicket);
}

function* ticketSaga() {
  yield all([fork(watchCreateTicket)]);
  yield all([fork(watchCreateComment)]);
  yield all([fork(watchGetTicket)]);
  yield all([fork(watchUpdateTicket)]);
  yield all([fork(watchGetTickets)]);
  yield all([fork(watchDeleteTicket)]);
}

export default ticketSaga;
