import { TicketActionTypes } from "./constants";
import { Ticket } from "models/Ticket";

type State = {
  tickets: Ticket[];
  ticket: Ticket | {};
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
};

const initialState: State = {
  tickets: [],
  ticket: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

const Tickets = (state = initialState, action: any) => {
  switch (action.type) {
    case TicketActionTypes.ALL_TICKET:
      return {
        ...state,
        loading: true,
      };
    case TicketActionTypes.GET_TICKET:
      return {
        ...state,
        loading: true,
      };
    case TicketActionTypes.CREATE_TICKET:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case TicketActionTypes.CREATE_COMMENT:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case TicketActionTypes.DELETE_TICKET:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case TicketActionTypes.UPDATE_TICKET:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case TicketActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === TicketActionTypes.CREATE_TICKET) {
        return {
          ...state,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === TicketActionTypes.CREATE_COMMENT) {
        return {
          ...state,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === TicketActionTypes.ALL_TICKET) {
        return {
          ...state,
          tickets: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === TicketActionTypes.GET_TICKET) {
        return {
          ...state,
          ticket: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === TicketActionTypes.UPDATE_TICKET) {
        return {
          ...state,
          ticket: action.payload.data,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === TicketActionTypes.DELETE_TICKET) {
        const newTickets = state.tickets.filter(
          (ticket) => ticket._id !== action.payload.data._id
        );
        return {
          ...state,
          tickets: newTickets,
          deleted: true,
        };
      }
      return state;
    case TicketActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === TicketActionTypes.CREATE_TICKET) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: false,
        };
      } else if (action.payload.actionType === TicketActionTypes.CREATE_COMMENT) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: false,
        };
      } else if (action.payload.actionType === TicketActionTypes.GET_TICKET) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === TicketActionTypes.UPDATE_TICKET) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case TicketActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      };
    default:
      return state;
  }
};

export default Tickets;
