import { APICore } from "./apiCore";

const api = new APICore();

function getUser(userId: any) {
  const baseUrl = `/users/` + userId;
  return api.get(`${baseUrl}`, "");
}

function getAllUser() {
  const baseUrl = "/users";
  return api.get(`${baseUrl}`, "");
}

function createUser(params: {
  adresse: string;
  email: string;
  password: string;
  fullName?: string;
  phone: number;
  role?: string;
  isActive?: boolean;
}) {
  const baseUrl = "/users";
  return api.create(`${baseUrl}`, params);
}

function updateUser(userId: string, userData: any) {
  const baseUrl = `/users/${userId}`;
  return api.update(`${baseUrl}`, userData);
}

function deleteUser(_id: any) {
  const baseUrl = `/users/${_id}`;
  return api.delete(`${baseUrl}`);
}

export { createUser, updateUser, deleteUser, getAllUser, getUser };
