import { useRedux } from "hooks";
import { useEffect, useState } from 'react';

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
};


export const useMenuItems = () => {
  const { appSelector } = useRedux();
  const { role } = appSelector((state) => ({
    role: state.Auth.user.role,
  }));

  const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);

  useEffect(() => {
    if (role === 'Administrateur') {
      setMenuItems([
        {
          key: "mon-compte",
          label: "Mon compte",
          isTitle: false,
          url: "/support/mes-billets",
          icon: "uil-user-circle",
        },
        {
          key: "users",
          label: "Utilisateurs",
          isTitle: false,
          icon: "uil-users-alt",
          children: [
            {
              key: "ds-users",
              label: "Liste des utilisateurs",
              url: "/support/utilisateurs",
              parentKey: "users",
            },
            {
              key: "ds-users-ajouter",
              label: "Ajouter un utilisateur",
              url: "/support/utilisateurs/ajouter",
              parentKey: "users",
            },
          ],
        },
        {
          key: "support",
          label: "Mon support",
          isTitle: false,
          icon: "uil-headphones-alt",
          children: [
            {
              key: "ds-billet",
              label: "Mes billets",
              url: "/support/mes-billets",
              parentKey: "support",
              icon: "uil-ticket",
            },
            {
              key: "ds-tciket",
              label: "Créer un ticket",
              url: "/support/creer-un-billet",
              parentKey: "support",
              icon: "uil-book-medical",
            },
          ],
        },
      ]);
    } else if (role === 'Client') {
      setMenuItems([
        {
          key: "mon-compte",
          label: "Mon compte",
          isTitle: false,
          url: "/support/mon-compte",
          icon: "uil-user-circle",
        },
        {
          key: "support",
          label: "Mon support",
          isTitle: false,
          icon: "uil-headphones-alt",
          children: [
            {
              key: "ds-billet",
              label: "Mes billets",
              url: "/support/mes-billets",
              parentKey: "support",
              icon: "uil-ticket",
            },
            {
              key: "ds-ticket",
              label: "Créer un ticket",
              url: "/support/creer-un-billet",
              parentKey: "support",
              icon: "uil-book-medical",
            },
          ],
        },
      ]);
    }
  }, [role]);

  return menuItems;
};
