import { TicketActionTypes } from "./constants";

export type TicketActionType = {
  type:
  | TicketActionTypes.API_RESPONSE_SUCCESS
  | TicketActionTypes.API_RESPONSE_ERROR
  | TicketActionTypes.ALL_TICKET
  | TicketActionTypes.GET_TICKET
  | TicketActionTypes.CREATE_TICKET
  | TicketActionTypes.CREATE_COMMENT
  | TicketActionTypes.UPDATE_TICKET
  | TicketActionTypes.DELETE_TICKET
  | TicketActionTypes.RESET;
  payload: {} | string;
};

export const ticketApiResponseSuccess = (
  actionType: string,
  data: any
): TicketActionType => ({
  type: TicketActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const ticketApiResponseError = (
  actionType: string,
  error: string
): TicketActionType => ({
  type: TicketActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const allTicket = (): TicketActionType => ({
  type: TicketActionTypes.ALL_TICKET,
  payload: {},
});

export const createComment = (commentData: any): TicketActionType => ({
  type: TicketActionTypes.CREATE_COMMENT,
  payload: commentData,
});

export const createTicket = (ticketData: any): TicketActionType => ({
  type: TicketActionTypes.CREATE_TICKET,
  payload: ticketData,
});

export const getTicket = (_id: any): TicketActionType => ({
  type: TicketActionTypes.GET_TICKET,
  payload: { _id },
});

export const updateTicket = (
  ticketId: string,
  updatedData: any
): TicketActionType => ({
  type: TicketActionTypes.UPDATE_TICKET,
  payload: { ticketId, updatedData },
});

export const deleteTicket = (_id: any): TicketActionType => ({
  type: TicketActionTypes.DELETE_TICKET,
  payload: { _id },
});

export const resetTicket = (): TicketActionType => ({
  type: TicketActionTypes.RESET,
  payload: {},
});
