export enum TicketActionTypes {
  API_RESPONSE_SUCCESS = "@@ticket/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@ticket/API_RESPONSE_ERROR",

  ALL_TICKET = "@@ticket/ALL_TICKET",
  GET_TICKET = "@@ticket/GET_TICKET",
  CREATE_TICKET = "@@ticket/CREATE_TICKET",
  CREATE_COMMENT = "@@ticket/CREATE_COMMENT",
  UPDATE_TICKET = "@@ticket/UPDATE_TICKET",
  DELETE_TICKET = "@@ticket/DELETE_TICKET",

  RESET = "@@ticket/RESET",
}
